/** @format */

// import { v4 } from 'uuid';
import {
  ActionTypes,
  INIT_APP,
  RECEIVE_INITIAL_DATA,
  UPDATE_IS_CHECKING_TICKET,
  START_SESSION,
} from './types';

type SystemState = {
  uuid: string;
  loaded: boolean;
  connected: boolean;
  isCheckingTicket: boolean;
  isTouchscreen: boolean;
  sessionId?: number;
};

export const initialState: SystemState = {
  uuid: '',
  loaded: false,
  connected: false,
  isCheckingTicket: false,
  isTouchscreen: false,
};

export const systemReducer = (state = initialState, action: ActionTypes) => {
  switch (action.type) {
    case INIT_APP:
      return {
        loaded: false,
        connected: false,
        uuid: action.meta.uuid,
        isCheckingTicket: false,
        isTouchscreen: action.meta.isTouchscreen,
      };
    case RECEIVE_INITIAL_DATA:
      return {
        ...state,
        loaded: true,
      };
    case UPDATE_IS_CHECKING_TICKET:
      return {
        ...state,
        isCheckingTicket: action.meta.value,
      };
    case START_SESSION:
      if (state.sessionId) {
        return state;
      }
      return {
        ...state,
        sessionId: Date.now(),
      };

    default:
      return state;
  }
};
