import React, {
  CSSProperties,
  MouseEventHandler,
  SyntheticEvent,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CloseIcon } from '../../img/close-56px.svg';
import { toggleCheckTicket } from '../../store/thunks';
import { ReactComponent as BackSpace } from '../../img/back-24px.svg';
import { ReactComponent as ShiftIcon } from '../../img/shift-24px.svg';
import TicketResults from './TicketResults';
import { motion } from 'framer-motion';
import { RootState } from '../../store/index';

interface Props {
  isTouchScreen: boolean;
}
enum Step {
  TicketId,
  ProtectionCode,
}

interface TicketDetails {
  id: string;
}

/**
 * long ticket for scroll
  const [ticketId, setTicketId] = useState<string>('622hnd');
  const [protectionCode, setProtectionCode] = useState<string>('1249'); */

const CheckTicket: React.FC<Props> = ({ isTouchScreen }) => {
  const [step, setStep] = useState<Step>(Step.TicketId);
  const [showTicket, setShowTicket] = useState(false);

  const [ticketId, setTicketId] = useState<string>('');
  const [protectionCode, setProtectionCode] = useState<string>('');

  const isEditing = !showTicket;
  const dispatch = useDispatch();
  const appId = useSelector((state: RootState) => state.system.uuid);

  const isValid =
    protectionCode.length === 4 &&
    (ticketId.length === 6 ||
      (ticketId.length === 16 && !isNaN(Number(ticketId))));

  const handleCheckTicket = () => {
    if (isValid) {
      setShowTicket(true);
    }
  };

  const handleBackSpace = () => {
    switch (step) {
      case Step.TicketId: {
        setTicketId(ticketId.slice(0, -1));
        break;
      }
      case Step.ProtectionCode: {
        setProtectionCode(protectionCode.slice(0, -1));
        break;
      }
    }
  };

  const handleKey = (key: string) => {
    switch (step) {
      case Step.TicketId: {
        setTicketId(`${ticketId}${key}`);
        break;
      }
      case Step.ProtectionCode: {
        setProtectionCode(`${protectionCode}${key}`);
        break;
      }
    }
  };
  const handleTicketNotFound = () => {
    setShowTicket(false);
  };

  return (
    <>
      <div
        style={{
          position: 'fixed',
          display: 'inline-block',
          left: 16,
          bottom: 16,
          color: '#707070',
          fontSize: 12,
          zIndex: 99999,
          fontFamily: 'monospace',
        }}
      >
        {appId}
      </div>
      <div className='fs-modal bg-darkest '>
        <CloseIcon
          className='closeButton'
          onClick={() => {
            dispatch(toggleCheckTicket());
          }}
        />{' '}
        {showTicket && (
          <TicketResults
            ticketId={ticketId}
            protectionCode={protectionCode}
            onError={handleTicketNotFound}
          />
        )}
        {isEditing && (
          <div className='flex flex-col items-center content-center w-screen h-screen'>
            <h1 className='text-white flex-grow-0 text-24 bold text-center py-16 mt-32'>
              Verificare bilet agenție
            </h1>
            <div className='flex flex-col flex-grow items-center content-center'>
              <div className='flex items-center content-center h-56 mb-48'>
                <InputBox
                  value={ticketId}
                  placeholder='Cod scurt / număr bilet'
                  active={step === Step.TicketId}
                  style={{ width: '280px' }}
                  onClick={() => {
                    setStep(Step.TicketId);
                  }}
                />
                <InputBox
                  value={protectionCode}
                  placeholder='Cod verificare'
                  active={step === Step.ProtectionCode}
                  style={{ width: '200px' }}
                  onClick={() => {
                    setStep(Step.ProtectionCode);
                  }}
                />

                <motion.div
                  initial={{ backgroundColor: '#414141' }}
                  whileTap={{ backgroundColor: '#ffd639' }}
                  onClick={handleBackSpace}
                  className='ml-4 inline-flex items-center content-center h-56 w-56 bg-darker rounded-4'
                >
                  <BackSpace />
                </motion.div>
              </div>
              <div>
                {step === Step.TicketId && <FullKeyPad onChange={handleKey} />}
                {step === Step.ProtectionCode && (
                  <NumericKeyPad onChange={handleKey} />
                )}
              </div>
              <motion.div
                style={{ minWidth: 320 }}
                onClick={handleCheckTicket}
                whileTap={{ backgroundColor: isValid ? '#F0BF00' : '#414141' }}
                className={`ml-4 inline-flex items-center content-center h-56 ${
                  isValid ? 'background-yellow' : 'background-gray'
                } rounded-4 text-16 bold`}
              >
                Verifică
              </motion.div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default CheckTicket;

interface InputBoxProps {
  style?: CSSProperties;
  value?: string;
  active?: boolean;
  placeholder?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}
const InputBox: React.FC<InputBoxProps> = (props) => {
  const { style, value, active, placeholder, onClick } = props;
  const showPlaceholder =
    placeholder !== undefined && (value ?? '').length === 0;
  const showValue = (value ?? '').length > 0;
  return (
    <div
      style={style}
      onClick={onClick}
      className={`inline-block h-56  bg-white mr-2 px-24 box-border rounded-4 text-dark text-16 bold ${
        active === true ? 'border-2 border-yellow border-solid lh-52' : 'lh-56'
      }`}
    >
      {showPlaceholder && (
        <span className='text-gray text-16 bold'>{placeholder}</span>
      )}
      {showValue && <>{value}</>}
    </div>
  );
};

interface KeyboardProps {
  onChange: (key: string) => void;
}

const FullKeyPad: React.FC<KeyboardProps> = (props) => {
  const { onChange } = props;
  const [caps, setCaps] = useState(false);
  const handleKey = (e: SyntheticEvent<HTMLDivElement>) => {
    const key = e.currentTarget.dataset.value ?? '';
    onChange(caps ? key?.toUpperCase() : key);
  };
  return (
    <div className='flex flex-col items-center content-center py-24'>
      <div className='flex flex-row mb-24'>
        {['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].map(
          (key, index) => (
            <Key key={key} value={key} small onClick={handleKey}>
              {key}
            </Key>
          )
        )}
      </div>
      <div className='flex flex-row mb-18'>
        {['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'].map((key) => (
          <Key key={key} value={key} onClick={handleKey}>
            {caps ? key.toUpperCase() : key}
          </Key>
        ))}
      </div>
      <div className='flex flex-row mb-18'>
        {['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'].map((key) => (
          <Key key={key} value={key} onClick={handleKey}>
            {caps ? key.toUpperCase() : key}
          </Key>
        ))}
      </div>
      <div className='flex flex-row mb-18'>
        <Key
          key='lshift'
          value={'shift'}
          style={{ backgroundColor: '#414141', width: 104 }}
          onClick={() => {
            setCaps(!caps);
          }}
        >
          <ShiftIcon />
        </Key>
        {['z', 'x', 'c', 'v', 'b', 'n', 'm'].map((key) => (
          <Key key={key} value={key} onClick={handleKey}>
            {caps ? key.toUpperCase() : key}
          </Key>
        ))}
        <Key
          key='rshift'
          value={'shift'}
          style={{ backgroundColor: '#414141', width: 104 }}
          onClick={() => {
            setCaps(!caps);
          }}
        >
          <ShiftIcon />
        </Key>
      </div>
    </div>
  );
};

const NumericKeyPad: React.FC<KeyboardProps> = (props) => {
  const { onChange } = props;

  const handleKey = (e: SyntheticEvent<HTMLDivElement>) => {
    const key = e.currentTarget.dataset.value ?? '';
    onChange(key);
  };
  return (
    <div className='grid grid-rows-4 grid-cols-3 items-center content-center py-24 gap-18'>
      {['7', '8', '9', '4', '5', '6', '1', '2', '3'].map((key, index) => (
        <Key key={key} value={key} style={{ width: 104 }} onClick={handleKey}>
          {key}
        </Key>
      ))}
      <div></div>
      <Key value='0' style={{ width: 104 }} onClick={handleKey}>
        0
      </Key>
    </div>
  );
};

const Key: React.FC<{
  value: string;
  small?: boolean;
  style?: CSSProperties;
  onClick?: MouseEventHandler | ((e: SyntheticEvent<HTMLDivElement>) => void);
}> = (props) => {
  const { value, small, children, style, onClick } = props;
  return (
    <motion.div
      style={style}
      data-value={value}
      onClick={onClick}
      whileTap={{ backgroundColor: '#F0BF00' }}
      className={`ml-4 mx-8 inline-flex items-center content-center background-white rounded-5 text-28 text-dark medium ${
        small === true ? 'h-48 w-64' : 'h-64 w-64'
      }`}
    >
      {children}
    </motion.div>
  );
};
