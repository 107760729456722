import React, { useEffect, useState } from 'react';
import { isNumber } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { wsMessage } from './store/system/actions';
import { RootState } from './store';

const RemoteManagement: React.FC = () => {
  const [temp, setTemp] = useState(-1000);
  const isRPI = navigator.userAgent.indexOf('Raspbian++') >= 0;
  const timer = React.useRef<number | undefined>(undefined);
  const dispatch = useDispatch();
  const { uuid, sessionId } = useSelector((state: RootState) => {
    return {
      uuid: state.system.uuid,
      sessionId: state.system.sessionId,
    };
  });
  const readTemperature = () => {
    console.log('reading temperature');
    fetch('http://localhost:8001/temp')
      .then(async (res) => {
        if (res.ok) {
          const js = await res.json();
          const jsTemp = Number(js.temp);
          if (isNumber(jsTemp)) {
            setTemp(jsTemp);
          } else {
            console.warn('response is not a number: ', js.temp);
          }
        }
      })
      .catch((err) => {
        console.debug('error reading temp ', err);
      });
  };

  useEffect(() => {
    console.debug('RM: is rpi: ', isRPI);
    if (isRPI) {
      timer.current = window.setInterval(readTemperature, 2000);
    }

    return () => {
      window.clearInterval(timer.current);
    };
  }, [isRPI]);

  useEffect(() => {
    if (temp === -1000) {
      return;
    }
    dispatch(
      wsMessage({
        appId: uuid,
        sessionId,
        rx_action: {
          type: 'RPI_TEMP',
          temp,
        },
        dt: 0,
      })
    );
    console.log('new temperature is ', temp);
  }, [temp, dispatch, sessionId, uuid]);

  return <></>;
};
export default RemoteManagement;
