/** @format */
import { combineReducers } from 'redux';
import {
  systemReducer,
  initialState as initialSystemState,
} from './system/reducers';
import {
  offerReducer,
  initialState as initialOfferState,
} from './offer/reducers';
import * as StoreTypes from './types';
import * as SystemActions from './system/actions';
import * as OfferActions from './offer/actions';
import {
  ticketReducer,
  initialState as initialVirtualTicketState,
} from './ticket/ticket-reducers';

export const rootReducer = combineReducers({
  system: systemReducer,
  offer: offerReducer,
  virtualTicket: ticketReducer,
});
export type RootState = ReturnType<typeof rootReducer>;

export const initialState: RootState = {
  system: initialSystemState,
  offer: initialOfferState,
  virtualTicket: initialVirtualTicketState,
};

export { StoreTypes, SystemActions, OfferActions };
