import React from 'react';

import { RootState } from '../../store';
import { Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import TicketControls from './VirtualTicketEditorControls';
import VirtualTicketBetRow from './VirtualTicketBetRow';
import { ReactComponent as InfoIcon } from '../../img/info-24px.svg';

const TicketEditor: React.FC<TicketEditorReduxProps> = (props) => {
  const {
    oids,
    bets,
    groups,
    ticket_mode,
    messages,
    castig,
    miza,
    legal,
    totalOddString,
    totalOdd,
    shortCode,
    systemGroupBets,
    combiGroupBets,
  } = props;
  if (oids.length === 0) {
    return (
      <div
        className='flex'
        style={{
          width: '100%',
          margin: 0,
          padding: 0,
          height: '200px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div className='flex flex-col items-center content-center'>
          <InfoIcon />
          <div className='text-13 semibold' style={{ color: '#85878B' }}>
            Biletul este gol
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className='ticket-body flex-shrink-0'>
        {ticket_mode !== 'COMBI' && (
          <>
            {oids.map((oid, index) => (
              <VirtualTicketBetRow
                key={oid}
                {...bets[oid]}
                editable={true}
                mode={ticket_mode}
                roundedTop={index !== 0}
              />
            ))}
          </>
        )}
        {ticket_mode === 'COMBI' && (
          <>
            {groups.map((group) => (
              <div key={group.name} className='mb-24'>
                <div
                  className='h-36 lh-36 text-11 text-white semibold flex mb-1'
                  style={{ marginRight: 54 }}
                >
                  <div className='inline-flex space-between flex-grow bg-gray-gradient rounded-t mr-1 px-12 box-border'>
                    <span>
                      GRUP <span className='text-yellow'>{group.name}</span>
                    </span>
                    <span>
                      {group.bets.length}{' '}
                      {group.bets.length !== 1 ? 'Pariuri' : 'Pariu'}
                    </span>
                  </div>
                  <div
                    className='inline-flex space-between bg-gray-gradient rounded-t px-12 box-border'
                    style={{ width: 111 }}
                  >
                    <span>Cota</span>
                    <span className='text-yellow'>{group.odd}</span>
                  </div>
                </div>

                {group.bets.map((oid, index) => (
                  <VirtualTicketBetRow
                    key={oid}
                    {...bets[oid]}
                    editable={true}
                    roundedTop={index !== 0}
                  />
                ))}
              </div>
            ))}
          </>
        )}
      </div>
      <TicketControls
        messages={messages}
        miza={miza}
        castig={castig}
        totalOdd={totalOdd}
        totalOddString={totalOddString}
        legal={legal}
        shortCode={shortCode}
        systemGroupBets={systemGroupBets}
        ticket_mode={ticket_mode}
        combiGroupBets={combiGroupBets}
      />
    </>
  );
};
const mapStateToPropsTicketEditor = (state: RootState) => {
  const {
    ticketServerState: {
      bets,
      codes,
      oids,
      ticket_mode,
      messages,
      castig,
      miza,
      legal,
      totalOddString,
      totalOdd,
      groups,
      shortCode,
      systemGroupBets,
      combiGroupBets,
    },
  } = state.virtualTicket;

  return {
    bets,
    codes,
    oids,
    ticket_mode,
    messages,
    castig,
    miza,
    legal,
    totalOddString,
    totalOdd,
    groups,
    shortCode,
    systemGroupBets,
    combiGroupBets,
  };
};

const mapDispatchActionsTicketEditor = (dispatch: Dispatch) => {
  return {
    dispatch,
  };
};
const ticketEditorConnector = connect(
  mapStateToPropsTicketEditor,
  mapDispatchActionsTicketEditor
);

type TicketEditorReduxProps = ConnectedProps<typeof ticketEditorConnector>;

const ConnectedTicketEditor = ticketEditorConnector(TicketEditor);
export default ConnectedTicketEditor;
