import React, { Fragment, useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import Calendar from './Calendar';
import CategoryFilter from './CategoryFilter';

import { toggleCheckTicket } from '../store/thunks';
import { ReactComponent as TaskIcon } from '../img/ic_task_black_24px.svg';
import VirtualOfferContentTouchScreen from './VirtualOfferContentTouchScreen';
import VirtualOfferContentTablet from './VirtualOfferContentTablet';

interface Props extends PropsFromRedux {
  isTouchscreen: boolean;
}

const Offer: React.FC<Props> = (props) => {
  const { isTouchscreen } = props;
  const dispatch = useDispatch();

  const showCheckTicket = () => {
    dispatch(toggleCheckTicket());
  };

  return (
    <>
      <Calendar isTouchscreen={isTouchscreen} />
      <CheckTicketButton onClick={showCheckTicket} />
      <CategoryFilter isTouchscreen={isTouchscreen} />

      {isTouchscreen && <VirtualOfferContentTouchScreen isTouchscreen />}
      {!isTouchscreen && <VirtualOfferContentTablet isTouchscreen={false} />}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {};
};

const mapDispatchActions = (dispatch: Dispatch) => {
  return {
    dispatch,
  };
};

const connector = connect(mapStateToProps, mapDispatchActions);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Offer);

const CheckTicketButton: React.FC<{ onClick: React.MouseEventHandler }> = ({
  onClick,
}) => {
  return (
    <div className='checkticket-button' onClick={onClick}>
      VERIFICA BILET
      <TaskIcon />
    </div>
  );
};
