import React from 'react';
import { RootState } from '../store';
import { Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { toggleTicketExpanded } from '../store/ticket/ticket-actions';
import VirtualTicketEditorHeader from './VirtualTicket/VirtualTicketEditorHeader';
import VirtualTicketEditor from './VirtualTicket/VirtualTicketEditor';
import VirtualTicketTabs from './VirtualTicket/VirtualTicketTabs';
import PreparedVirtualTicketsHeader from './VirtualTicket/PreparedVirtualTicketsHeader';
import PreparedVirtualTicket from './VirtualTicket/PreparedVirtualTicket';

const VirtualTickets: React.FC<VirtualTicketReduxProps> = (props) => {
  const { isExpanded, toggleExpanded, isShowingPreparedTickets } = props;

  const showTicket = isExpanded && !isShowingPreparedTickets;
  const showPreparedTickets = isExpanded && isShowingPreparedTickets;
  return (
    <>
      {isExpanded && <div className='fs-bg-shadow' onClick={toggleExpanded} />}
      <div className='virtualTickets'>
        <div className='ticket-header-wrapper'>
          {isExpanded && <VirtualTicketTabs />}
          {!isShowingPreparedTickets && <VirtualTicketEditorHeader />}
          {isShowingPreparedTickets && <PreparedVirtualTicketsHeader />}
        </div>
        <div className='vticket-wrapper'>
          {showTicket && <VirtualTicketEditor />}
          {showPreparedTickets && <PreparedVirtualTicket />}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const { isExpanded, isShowingPreparedTickets } = state.virtualTicket;
  return {
    isExpanded,
    isShowingPreparedTickets,
  };
};

const mapDispatchActions = (dispatch: Dispatch) => {
  return {
    dispatch,
    toggleExpanded: () => dispatch(toggleTicketExpanded()),
  };
};
const virtualTicketConnector = connect(mapStateToProps, mapDispatchActions);
type VirtualTicketReduxProps = ConnectedProps<typeof virtualTicketConnector>;
const ConnectedVirtualTicket = virtualTicketConnector(VirtualTickets);
export default ConnectedVirtualTicket;
