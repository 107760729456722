import React, { SyntheticEvent } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RootState } from '../store';

import { Category } from '../store/offer/types';
import EOButton from './EOButton';
import { changeSelectedCategory } from '../store/offer/actions';
import * as selectors from '../store/selectors';

type AppProps = {
  children?: React.ReactChildren;
  onChangeCategory: (category: string) => void;
  selectedCategory?: string | undefined;
  categories: Array<Category>;
  isTouchscreen: boolean;
};

type TargetWithValue = {
  value: string;
};

const CategoryFilter: React.FC<AppProps> = (props: AppProps) => {
  const { categories, onChangeCategory, selectedCategory } = props;

  const changeCategory = (e: SyntheticEvent<TargetWithValue>) => {
    onChangeCategory(e.currentTarget.value);
  };

  return (
    <ul className='scrollable'>
      {categories.map((cat: Category) => (
        <li key={cat.id}>
          <EOButton
            value={cat.id}
            selected={`${cat.id}` === selectedCategory}
            title={cat.name}
            onClick={changeCategory}
            width='100%'
          />
        </li>
      ))}
    </ul>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    categories: selectors.getCategoriesInSelectedDay(state),
    selectedCategory: selectors.getSelectedCategoryId(state),
  };
};

const mapDispatchActions = (dispatch: Dispatch) => {
  return {
    onChangeCategory: (category: string) => {
      dispatch(changeSelectedCategory(category));
    },
  };
};

export default connect(mapStateToProps, mapDispatchActions)(CategoryFilter);
