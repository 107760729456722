/** @format */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { throttle } from 'lodash';
import { thunkInitApp } from './store/thunks';
import { RootState } from './store';
import Loading from './components/Loading';
import Offer from './components/Offer';
import './App.scss';
import TournamentDetails from './components/TournamentDetails';
import VirtualTickets from './components/VirtualTickets';
import CheckTicket from './components/CheckTicket/CheckTicket';

import { useWindowSize } from './components/CustomHooks';
import MoviePlayer from './components/MoviePlayer';
import { wsMessage } from './store/system/actions';

const INACTIVITY_TIMEOUT = Number(process.env.REACT_APP_INACTIVITY_TIMEOUT);
const MOVIE_LOOP_MAX = Number(process.env.REACT_APP_MOVIE_LOOP_MAX);

const reloadApp = () => {
  try {
    if (window.Android !== undefined && Android.reload !== undefined) {
      Android.reload();
    } else {
      window.location.reload();
    }
  } catch (ex) {
    console.error('reloadApp err ', ex);
  }
};

const App: React.FC = () => {
  const [idle, setIdle] = React.useState(0);
  const timer = React.useRef<number | undefined>(undefined);
  const [showMovie, setShowingMovie] = React.useState(false);

  const {
    showTournamentDetails,
    loaded,
    isCheckingTicket,
    appId,
    sessionId,
  } = useSelector((state: RootState) => {
    return {
      showTournamentDetails: state.offer.selectedTournament,
      loaded: state.system.loaded,
      isCheckingTicket: state.system.isCheckingTicket,
      appId: state.system.uuid,
      sessionId: state.system.sessionId,
    };
  });

  const dispatch = useDispatch();

  const size = useWindowSize();
  const isTouchscreen = size.isTouchScreen;
  useEffect(() => {
    dispatch(thunkInitApp(isTouchscreen));
  }, [dispatch, isTouchscreen]);

  const logMovie = React.useCallback(() => {
    dispatch(
      wsMessage({
        appId,
        sessionId,
        isTouchscreen,
        rx_action: {
          type: 'SHOW_MOVIE',
        },
        dt: 0,
      })
    );
  }, [appId, dispatch, isTouchscreen, sessionId]);

  const logReloadApp = React.useCallback(() => {
    dispatch(
      wsMessage({
        appId,
        isTouchscreen,
        rx_action: {
          type: 'RELOAD_APP',
        },
        dt: 0,
      })
    );
  }, [appId, dispatch, isTouchscreen]);

  const handleRefreshForInactivity = React.useCallback(() => {
    setShowingMovie(true);
    logMovie();

    timer.current = window.setTimeout(() => {
      logReloadApp();
      reloadApp();
    }, MOVIE_LOOP_MAX);
  }, [logMovie, logReloadApp]);

  useEffect(() => {
    window.clearTimeout(timer.current);
    timer.current = window.setTimeout(
      handleRefreshForInactivity,
      INACTIVITY_TIMEOUT
    );
    return () => {
      window.clearTimeout(timer.current);
    };
  }, [idle, handleRefreshForInactivity]);

  const updateIdle = (event: Event) => {
    setIdle(new Date().getTime());
  };

  useEffect(() => {
    if (!loaded) {
      return () => {
        console.log(2 + 2);
      };
    }

    const throttledUpdateidle = throttle(updateIdle, 1000);
    document.addEventListener('click', throttledUpdateidle);
    document.addEventListener('touchmove', throttledUpdateidle);
    document.addEventListener('onmousedown', throttledUpdateidle);
    document.addEventListener('mousewheel', throttledUpdateidle);
    document.addEventListener('touchstart', throttledUpdateidle);

    return () => {
      document.removeEventListener('click', throttledUpdateidle);
      document.removeEventListener('touchmove', throttledUpdateidle);
      document.removeEventListener('onmousedown', throttledUpdateidle);
      document.removeEventListener('mousewheel', throttledUpdateidle);
      document.removeEventListener('touchstart', throttledUpdateidle);
    };
  }, [loaded]);

  if (loaded)
    return (
      <>
        {showMovie && (
          <MoviePlayer
            isTouchscreen={size.isTouchScreen}
            onInteraction={() => {
              logReloadApp();
              reloadApp();
            }}
          />
        )}
        {!showMovie && (
          <>
            <Offer isTouchscreen={size.isTouchScreen} />
            {isCheckingTicket && (
              <CheckTicket isTouchScreen={size.isTouchScreen} />
            )}
            {showTournamentDetails && (
              <TournamentDetails isTouchscreen={size.isTouchScreen} />
            )}
            <VirtualTickets />
          </>
        )}
      </>
    );
  return <Loading />;
};

export default App;
