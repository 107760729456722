import { v4 as uuidv4 } from 'uuid';

declare global {
  // eslint-disable-next-line no-var
  var Android: AndroidTablet;
}
export interface AndroidTablet {
  reload: () => void;
  getAPKVersion: () => string | undefined;
}

export const getDeviceVersion = (): string => {
  let version: string | undefined;
  try {
    version = Android.getAPKVersion();
  } catch (ex) {
    // console.warn(ex);
  }
  if (!version) {
    const v = navigator.appVersion.match(/Chrome\/(\S+)/);
    if (v) {
      version = `Ch: ${v[0].split('/')[1]}`;
    } else {
      version = '...';
    }
  }
  return version;
};

export const getAppId = (): string => {
  let appId = getCookie('appId');
  if (!appId) {
    appId = uuidv4();
    setCookie('appId', appId);
  }
  return appId;
};

const getCookie = (name: string): string | undefined => {
  const cookies = document.cookie.split('; ');
  if (cookies.length === 0) {
    return undefined;
  }
  return cookies.find((row) => row.startsWith(name))?.split('=')[1];
};

const setCookie = (name: string, value: string): void => {
  document.cookie = `${name}=${value}; expires=Sun, 16 Jul 3567 06:23:41 GMT; path=/`;
};

export { setCookie };
