import React, { CSSProperties } from 'react';
import Logo from '../img/Logo.png';

type AppProps = {
  children?: React.ReactChildren;
  //   initApp: typeof initApp;
};

const Loading: React.FC<AppProps> = (props: AppProps) => {
  return (
    <div style={styles.main}>
      <div style={styles.container}>
        <div style={styles.messageBox}>
          <p style={styles.messageTitle}>OFERTA SE DESCHIDE</p>
          <p style={styles.messageText}>Vă rugăm așteptați</p>
        </div>
      </div>
      <img className='logo-container' src={Logo} alt='Fortuna Logo' />
      <div className='withYellowDecoration' />
    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  main: {
    transition: 'opacity 0.5s',
    opacity: '1',
  },
  container: {
    color: '#eee',
    fontSize: '24',
    textAlign: 'center',
    position: 'fixed',
    width: '100%',
    height: '100%',
    background: '#000',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
  messageBox: {
    display: 'inline-block',
    margin: 'auto',
  },
  messageTitle: {
    fontWeight: 600,
    fontSize: 34,
  },
  messageText: {
    fontWeight: 600,
    fontSize: 16,
  },
};

// export default connect(null, { initApp })(Loading);
export default Loading;
