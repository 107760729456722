/** @format */

import {
  INIT_APP,
  RECEIVE_INITIAL_DATA,
  UPDATE_IS_CHECKING_TICKET,
  ActionTypes,
  WS_CONNECT,
  WS_CONNECTED,
  WS_DISCONNECTED,
  WS_MESSAGE,
  START_SESSION,
} from './types';

export function initApp(uuid: string, isTouchscreen: boolean): ActionTypes {
  return {
    type: INIT_APP,
    meta: {
      uuid,
      isTouchscreen,
    },
  };
}

export function receiveInitialData(version: string): ActionTypes {
  return {
    type: RECEIVE_INITIAL_DATA,
    meta: {
      version,
    },
  };
}

export function updateIsCheckingTicket(value: boolean): ActionTypes {
  return {
    type: UPDATE_IS_CHECKING_TICKET,
    meta: {
      value,
    },
  };
}

export function wsConnected(): ActionTypes {
  return {
    type: WS_CONNECTED,
  };
}

export function wsConnect(url: string): ActionTypes {
  return {
    type: WS_CONNECT,
    meta: { url },
  };
}

export function wsDisconnected(reason = ''): ActionTypes {
  return {
    type: WS_DISCONNECTED,
    meta: { reason },
  };
}
export function wsMessage(message: any): ActionTypes {
  return {
    type: WS_MESSAGE,
    meta: { message },
  };
}

export function startSession(): ActionTypes {
  return { type: START_SESSION };
}
