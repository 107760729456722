/**
 * /*
 * action types
 *
 * @format
 */

import { initialState } from './reducers';

export const INIT_APP = 'INIT_APP';
export const RECEIVE_INITIAL_DATA = 'RECEIVE_INITIAL_DATA';
export const UPDATE_IS_CHECKING_TICKET = 'UPDATE_IS_CHECKING_TICKET';
export const UPDATE_FF = 'UPDATE_FF';

export const WS_CONNECT = 'WS_CONNECT';
export const WS_CONNECTING = 'WS_CONNECTING';
export const WS_CONNECTED = 'WS_CONNECTED';
export const WS_DISCONNECT = 'WS_DISCONNECT';
export const WS_DISCONNECTED = 'WS_DISCONNECTED';
export const WS_MESSAGE = 'WS_MESSAGE';
export const START_SESSION = 'START_SESSION';

interface InitAppAction {
  type: typeof INIT_APP;
  meta: {
    uuid: string;
    isTouchscreen: boolean;
  };
}

interface ReceiveInitialData {
  type: typeof RECEIVE_INITIAL_DATA;
  meta: {
    version: string;
  };
}

interface UpdateIsCheckingTicket {
  type: typeof UPDATE_IS_CHECKING_TICKET;
  meta: {
    value: boolean;
  };
}

interface StartSession {
  type: typeof START_SESSION;
}

interface WSConnect {
  type: typeof WS_CONNECT;
  meta: {
    url: string;
  };
}

interface WSConnecting {
  type: typeof WS_CONNECTING;
}

interface WSConnected {
  type: typeof WS_CONNECTED;
}

interface WSDisconnect {
  type: typeof WS_DISCONNECT;
}

interface WSDisconnected {
  type: typeof WS_DISCONNECTED;
  meta: {
    reason: string;
  };
}

interface WSMessage {
  type: typeof WS_MESSAGE;
  meta: {
    message: any;
  };
}

export type ActionTypes =
  | InitAppAction
  | ReceiveInitialData
  | UpdateIsCheckingTicket
  | WSConnect
  | WSConnecting
  | WSConnected
  | WSDisconnect
  | WSDisconnected
  | WSMessage
  | StartSession;

export type AppState = typeof initialState;
