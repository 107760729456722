// middleware/middleware.js

import * as actions from '../system/actions';
import { ActionTypes } from '../system/types';

const socketMiddleware = () => {
  let socket: WebSocket | undefined;
  let ws_url = '';
  const onOpen = (store: any) => (event: WebSocketEventMap['open']) => {
    // console.log('websocket open ', event);
    store.dispatch(actions.wsConnected());
  };

  const onClose = (store: any) => (event: WebSocketEventMap['close']) => {
    const ev = event as CloseEvent;
    // console.log('ws closed, code: ', ev.code, ' reason: ', ev.reason);
    if (ev.code !== 4001) {
      // console.log('attempting to reconnect after timeout');
      setTimeout(() => {
        // console.log('reconnect timeout..');
        store.dispatch(actions.wsConnect(ws_url));
      }, 3000);
    } else {
      // console.log('not reconnecting..');
    }
    store.dispatch(actions.wsDisconnected());
  };

  const onError = (store: any) => (event: WebSocketEventMap['error']) => {
    const ev = event as ErrorEvent;
    console.error('websocket error, ', event);
    store.dispatch(actions.wsDisconnected(ev.error));
  };

  const onMessage = (store: any) => (event: WebSocketEventMap['message']) => {
    // console.log('ws message: ', event);
    const payload = JSON.parse(event.data);
    // console.log('received server message ', payload);

    switch (payload.type) {
      // case 'update_ff':
      //   let ffMovie: boolean = payload.ff.ffMovie || false;
      //   store.dispatch(thunkUpdateFF(ffMovie));
      //   break;
      default:
        break;
    }
  };

  // the middleware part of this function
  return (store: any) => (next: any) => (action: ActionTypes) => {
    switch (action.type) {
      case 'WS_CONNECT':
        if (socket !== undefined) {
          socket.onclose = null;
          socket.close(4001);
        }

        // connect to the remote host
        ws_url = action.meta.url;
        try {
          socket = new WebSocket(ws_url);
          // websocket handlers
          socket.onmessage = onMessage(store);
          socket.onclose = onClose(store);
          socket.onopen = onOpen(store);
          socket.onerror = onError(store);
        } catch (ex) {
          console.error('WS error creating websocket ', ex);
        }

        break;
      case 'WS_DISCONNECT':
        if (socket !== undefined) {
          socket.close(4001, 'not reconnecting.');
        }
        socket = undefined;
        // console.debug('websocket closed');
        break;
      case 'WS_MESSAGE':
        // console.log('sending a message', action.meta.message);
        if (socket?.readyState === WebSocket.OPEN) {
          try {
            socket?.send(
              JSON.stringify({ type: 'MSG', message: action.meta.message })
            );
          } catch (ex) {
            console.warn('ws send exception: ', ex);
          }
        }
        break;
      default:
        // console.log('the next action:', action);
        return next(action);
    }
  };
};

export default socketMiddleware();
