/** @format */
import { StoreTypes } from '..';
import {
  initApp,
  receiveInitialData,
  updateIsCheckingTicket,
  wsConnect,
  startSession,
} from '../system/actions';
import {
  changeSelectedDate,
  updateToday,
  resetOffer,
  updateSelectedOdds,
  selectPendingOdd,
} from '../offer/actions';
import {
  virtualTicketStartSession,
  virtualTicketUpdate,
} from '../ticket/ticket-actions';
import { OfferStore } from '../types';
import { getAppId } from '../../hardware';
import { VirtualTicketState } from '../ticket/ticket-types';
import { wsMessage } from '../system/actions';
import { setCookie } from '../../hardware';

const API_SYSTEM = process.env.REACT_APP_API;
const API_VIRTUAL_TICKET = process.env.REACT_APP_VIRTUAL_TICKET_API;
const WS_URL = process.env.REACT_APP_WS_URL || 'ws://';

const reloadApp = () => {
  try {
    if (window.Android !== undefined && Android.reload !== undefined) {
      Android.reload();
    } else {
      window.location.reload();
    }
  } catch (ex) {
    console.error('reloadApp err ', ex);
  }
};

export const thunkInitApp = (
  isTouchscreen: boolean
): StoreTypes.AppThunk => async (dispatch, getState) => {
  const appId = getAppId();

  const restrictedIDS: string[] = [
    '74f5f886-90a6-41f4-94f5-8805917fa75e',
    '2df70eab-461c-4497-be89-d661283e7594',
  ];

  if (restrictedIDS.indexOf(appId) >= 0) {
    console.warn(`found restricted id ${appId}.. reloading`);
    setCookie('appId', '');
    reloadApp();
  }

  dispatch(initApp(appId, isTouchscreen));
  dispatch(wsConnect(WS_URL));
  dispatch(virtualTicketSessionStart());
  const retry = () => {
    return fetchInitialData().then(
      (data) => {
        dispatch(resetOffer(data));
        dispatch(receiveInitialData('01:23'));
        dispatch(updateToday());
        dispatch(setInitialDate());
        dispatch(startSession());
      },
      (error) => {
        console.error('error fetching data ', error);
        setTimeout(() => dispatch(retry), 2000);
      }
    );
  };
  retry();
};

// Standard variation
function fetchJSON<T>(url: string): Promise<T> {
  return fetch(url).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json() as Promise<T>;
  });
}

function fetchInitialData() {
  return fetchJSON<OfferStore.OfferData>(`${API_SYSTEM}offer.json`);
}

export const changeDate = (date: string): StoreTypes.AppThunk => (
  dispatch,
  getState
) => {
  const state = getState();
  let category = `${state.offer.days[date].categories[0]}`;
  if (
    state.offer.days[date].categories.findIndex((c, i) => `${c}` === '3') >= 0
  ) {
    category = '3';
  }
  dispatch(changeSelectedDate(date, category));
};

export const setInitialDate = (): StoreTypes.AppThunk => (
  dispatch,
  getState
) => {
  const state = getState();
  const date = state.offer.daysIds[0];
  let category = state.offer.days[date].categories[0];
  if (state.offer.days[date].categories.findIndex((c) => `${c}` === '3') >= 0) {
    category = '3';
  }

  dispatch(changeSelectedDate(date, category));
};

export const toggleCheckTicket = (): StoreTypes.AppThunk => (
  dispatch,
  getState
) => {
  const state = getState();
  const { isCheckingTicket } = state.system;
  dispatch(updateIsCheckingTicket(!isCheckingTicket));
};

export const virtualTicketSessionStart = (): StoreTypes.AppThunk => (
  dispatch,
  getState
) => {
  // const state = getState();
  fetch(`${API_VIRTUAL_TICKET}/ticket/start-session/`, {
    method: 'POST',
    body: '',
  })
    .then((res) => res.json())
    .then((json) => {
      console.log('got json: ', json);
      const { cookies } = json;
      dispatch(virtualTicketStartSession(cookies));
    })
    .catch((error) => {
      console.error('error starting session: ', error);
    });
};

export const virtualTicketAddBet = (
  code: string,
  oid: string,
  oddv: string,
  b_id: string
): StoreTypes.AppThunk => (dispatch, getState) => {
  const {
    system: { sessionId },
    virtualTicket: { cookies, currentTicket, shopReady },
  } = getState();
  if (getState().virtualTicket.ticketServerState.oids.indexOf(oid) >= 0) {
    dispatch(virtualTicketRemoveBet(code, oid, b_id));
    return;
  }
  document.dispatchEvent(new Event('click'));
  // dispatch(selectPendingOdd(b_id, code, oid, true));

  fetch(`${API_VIRTUAL_TICKET}/ticket/bet/add`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      code,
      oid,
      oddv,
      sessionId,
      cookies,
      ticket: currentTicket,
      shopReady: shopReady[currentTicket - 1],
    }),
  })
    .then((res) => res.json())
    .then((json) => {
      console.log('got json: ', json);
      // dispatch(selectPendingOdd(b_id, code, oid, false));
      const data = json['ticket'] as VirtualTicketState;
      const { oids, bets } = data;
      const betIds = oids.map((oid) => {
        return bets[oid].matchId;
      });
      dispatch(updateSelectedOdds(betIds, oids));
      dispatch(virtualTicketUpdate(json));
    })
    .catch((error) => {
      console.error('error starting session: ', error);
      dispatch(selectPendingOdd(b_id, code, oid, false));
    });
};

export const virtualTicketRemoveBet = (
  code: string,
  oid: string,
  b_id: string | undefined
): StoreTypes.AppThunk => (dispatch, getState) => {
  const {
    system: { sessionId },
    virtualTicket: { cookies, currentTicket },
  } = getState();
  const OP = `virtualTicketRemoveBet code: [${code}] oid: [${oid}] `;
  document.dispatchEvent(new Event('click'));
  if (undefined !== b_id) {
    // dispatch(selectPendingOdd(b_id, code, oid, true));
  }

  fetch(`${API_VIRTUAL_TICKET}/ticket/bet/remove`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      code,
      oid,
      sessionId,
      cookies,
      ticket: currentTicket,
    }),
  })
    .then((res) => res.json())
    .then((json) => {
      console.debug(OP, 'got json: ', json);

      const data = json['ticket'] as VirtualTicketState;
      const { oids, bets } = data;
      const betIds = oids.map((oid) => {
        return bets[oid].matchId;
      });

      if (undefined !== b_id) {
        // dispatch(selectPendingOdd(b_id, code, oid, false));
      }
      dispatch(updateSelectedOdds(betIds, oids));
      dispatch(virtualTicketUpdate(json));
    })
    .catch((error) => {
      console.error(OP, error);
      if (undefined !== b_id) {
        dispatch(selectPendingOdd(b_id, code, oid, false));
      }
    });
};

export const virtualTicketChangeMiza = (miza: number): StoreTypes.AppThunk => (
  dispatch,
  getState
) => {
  const {
    system: { sessionId },
    virtualTicket: { cookies, currentTicket },
  } = getState();
  const OP = `virtualTicketChangeMixa [${miza}] `;
  document.dispatchEvent(new Event('click'));

  fetch(`${API_VIRTUAL_TICKET}/ticket/miza`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ miza, sessionId, cookies, ticket: currentTicket }),
  })
    .then((res) => res.json())
    .then((json) => {
      console.debug(OP, ' got json: ', json);
      dispatch(virtualTicketUpdate(json));
      const data = json['ticket'] as VirtualTicketState;
      const { oids, bets } = data;
      const betIds = oids.map((oid) => {
        return bets[oid].matchId;
      });

      console.debug(OP, ' updating selected odds: ', betIds, oids);
      dispatch(updateSelectedOdds(betIds, oids));
    })
    .catch((error) => {
      console.error(OP, error);
    });
};

export const virtualTicketPrepare = (): StoreTypes.AppThunk => (
  dispatch,
  getState
) => {
  const {
    system: { sessionId, uuid, isTouchscreen },
    virtualTicket: { cookies, currentTicket },
  } = getState();

  const OP = `virtualTicketPrepare`;
  document.dispatchEvent(new Event('click'));
  fetch(`${API_VIRTUAL_TICKET}/ticket/prepare`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ sessionId, cookies, ticket: currentTicket }),
  })
    .then((res) => res.json())
    .then((json) => {
      console.debug(OP, 'got json: ', json);
      const now = new Date();
      const time =
        ('0' + now.getHours()).slice(-2) +
        ':' +
        ('0' + now.getMinutes()).slice(-2);
      if (json['ticket']) {
        json['ticket']['prep_time'] = time;
      }

      dispatch(virtualTicketUpdate(json, true));
      const data = json['ticket'] as VirtualTicketState;
      const { oids, bets } = data;

      const betIds = oids.map((oid) => {
        return bets[oid].matchId;
      });

      dispatch(
        wsMessage({
          appId: uuid,
          sessionId,
          isTouchscreen,
          rx_action: {
            type: 'VT_PREPARED',
            code: data.shortCode,
            ticketId: data.ticketId,
            ticketMode: data.ticket_mode,
            ticketMiza: data.miza,
            ticketBets: oids.length,
            ticketCastig: data.castig,
            ticketActiveId: data.activeId,
          },
          dt: 0,
        })
      );

      dispatch(updateSelectedOdds(betIds, oids));
    })
    .catch((error) => {
      console.error(OP, error);
    });
};

export const changeCurrentTicket = (ticket: number): StoreTypes.AppThunk => (
  dispatch,
  getState
) => {
  const {
    system: { sessionId },
    virtualTicket: { cookies, currentTicket, shopReady },
  } = getState();
  const OP = `changeCurrentTicket [${ticket}]`;
  document.dispatchEvent(new Event('click'));

  fetch(`${API_VIRTUAL_TICKET}/ticket/change-current`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      sessionId,
      cookies,
      ticket,
      shopReady: shopReady[ticket - 1],
    }),
  })
    .then((res) => res.json())
    .then((json) => {
      console.log(OP, ' json: ', json);
      dispatch(virtualTicketUpdate(json));
      const data = json['ticket'] as VirtualTicketState;
      const { oids, bets } = data;
      const betIds = oids.map((oid) => {
        return bets[oid].matchId;
      });

      console.debug(OP, ' updating selected odds: ', betIds, oids);
      dispatch(updateSelectedOdds(betIds, oids));
    })
    .catch((error) => {
      console.error(OP, error);
    });
};

export const changeTicketType = (type: string): StoreTypes.AppThunk => (
  dispatch,
  getState
) => {
  const {
    system: { sessionId },
    virtualTicket: { cookies, currentTicket, shopReady },
  } = getState();
  const OP = `changeTicketType [${type}]`;
  document.dispatchEvent(new Event('click'));

  fetch(`${API_VIRTUAL_TICKET}/ticket/type`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      sessionId,
      cookies,
      type,
      ticket: currentTicket,
      shopReady: shopReady[currentTicket - 1],
    }),
  })
    .then((res) => res.json())
    .then((json) => {
      console.log(OP, ' json: ', json);
      const data = json['ticket'] as VirtualTicketState;
      if (!data) {
        console.error('did not receive ticket data');
        return;
      }
      dispatch(virtualTicketUpdate(json));
      const { oids, bets } = data;
      const betIds = oids.map((oid) => {
        return bets[oid].matchId;
      });

      console.debug(OP, ' updating selected odds: ', betIds, oids);
      dispatch(updateSelectedOdds(betIds, oids));
    })
    .catch((error) => {
      console.error(OP, error);
    });
};

export const changeBetGroup = (
  code: string,
  oid: string,
  group: string
): StoreTypes.AppThunk => (dispatch, getState) => {
  const {
    system: { sessionId },
    virtualTicket: { cookies, currentTicket, shopReady },
  } = getState();
  const OP = `changeBetGroup code: [${code}] oid: [${oid}] group: [${group}]`;
  document.dispatchEvent(new Event('click'));

  fetch(`${API_VIRTUAL_TICKET}/ticket/changebetsgroup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      sessionId,
      cookies,
      code,
      oid,
      group,
      ticket: currentTicket,
      shopReady: shopReady[currentTicket - 1],
    }),
  })
    .then((res) => res.json())
    .then((json) => {
      console.log(OP, ' json: ', json);
      const data = json['ticket'] as VirtualTicketState;
      if (!data) {
        console.error('did not receive ticket data');
        return;
      }
      dispatch(virtualTicketUpdate(json));
      const { oids, bets } = data;
      const betIds = oids.map((oid) => {
        return bets[oid].matchId;
      });

      console.debug(OP, ' updating selected odds: ', betIds, oids);
      dispatch(updateSelectedOdds(betIds, oids));
    })
    .catch((error) => {
      console.error(OP, error);
    });
};

export const changeFixedBet = (
  code: string,
  oid: string,
  fixed: boolean
): StoreTypes.AppThunk => (dispatch, getState) => {
  const {
    system: { sessionId },
    virtualTicket: { cookies, currentTicket, shopReady },
  } = getState();
  const OP = `changeFixedBet code: [${code}] oid: [${oid}] group: [${fixed}]`;
  document.dispatchEvent(new Event('click'));

  fetch(`${API_VIRTUAL_TICKET}/ticket/changefixedbet`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      sessionId,
      cookies,
      code,
      oid,
      fixed,
      ticket: currentTicket,
      shopReady: shopReady[currentTicket - 1],
    }),
  })
    .then((res) => res.json())
    .then((json) => {
      console.log(OP, ' json: ', json);
      const data = json['ticket'] as VirtualTicketState;
      if (!data) {
        console.error('did not receive ticket data');
        return;
      }
      dispatch(virtualTicketUpdate(json));
      const { oids, bets } = data;
      const betIds = oids.map((oid) => {
        return bets[oid].matchId;
      });

      console.debug(OP, ' updating selected odds: ', betIds, oids);
      dispatch(updateSelectedOdds(betIds, oids));
    })
    .catch((error) => {
      console.error(OP, error);
    });
};

export const changeSystemBetGroupChecked = (
  index: string,
  checked: boolean
): StoreTypes.AppThunk => (dispatch, getState) => {
  const {
    system: { sessionId },
    virtualTicket: { cookies, currentTicket, shopReady },
  } = getState();
  const OP = `changeFixedBet index: [${index}] checked: [${checked}]`;
  document.dispatchEvent(new Event('click'));

  fetch(`${API_VIRTUAL_TICKET}/ticket/markbetgroup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      sessionId,
      cookies,
      index,
      checked,
      ticket: currentTicket,
      shopReady: shopReady[currentTicket - 1],
    }),
  })
    .then((res) => res.json())
    .then((json) => {
      console.log(OP, ' json: ', json);
      const data = json['ticket'] as VirtualTicketState;
      if (!data) {
        console.error('did not receive ticket data');
        return;
      }
      dispatch(virtualTicketUpdate(json));
      const { oids, bets } = data;
      const betIds = oids.map((oid) => {
        return bets[oid].matchId;
      });

      console.debug(OP, ' updating selected odds: ', betIds, oids);
      dispatch(updateSelectedOdds(betIds, oids));
    })
    .catch((error) => {
      console.error(OP, error);
    });
};

export const changeSystemBetGroupValue = (
  index: string,
  value: number
): StoreTypes.AppThunk => (dispatch, getState) => {
  const {
    system: { sessionId },
    virtualTicket: { cookies, currentTicket, shopReady },
  } = getState();
  const OP = `changeSystemBetGroupMiza index: [${index}] value: [${value}]`;
  document.dispatchEvent(new Event('click'));

  fetch(`${API_VIRTUAL_TICKET}/ticket/system-group-value`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      sessionId,
      cookies,
      index,
      value,
      ticket: currentTicket,
      shopReady: shopReady[currentTicket - 1],
    }),
  })
    .then((res) => res.json())
    .then((json) => {
      console.log(OP, ' json: ', json);
      const data = json['ticket'] as VirtualTicketState;
      if (!data) {
        console.error('did not receive ticket data');
        return;
      }
      dispatch(virtualTicketUpdate(json));
      const { oids, bets } = data;
      const betIds = oids.map((oid) => {
        return bets[oid].matchId;
      });

      console.debug(OP, ' updating selected odds: ', betIds, oids);
      dispatch(updateSelectedOdds(betIds, oids));
    })
    .catch((error) => {
      console.error(OP, error);
    });
};

export const changeCombiBetGroupValue = (
  group: boolean,
  index: string,
  value: number
): StoreTypes.AppThunk => (dispatch, getState) => {
  const {
    system: { sessionId },
    virtualTicket: { cookies, currentTicket, shopReady },
  } = getState();
  const OP = `changeCombiBetGroupValue index: [${index}] value: [${value}]`;
  document.dispatchEvent(new Event('click'));

  fetch(`${API_VIRTUAL_TICKET}/ticket/combi-group-value`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      sessionId,
      cookies,
      index,
      value,
      group,
      ticket: currentTicket,
      shopReady: shopReady[currentTicket - 1],
    }),
  })
    .then((res) => res.json())
    .then((json) => {
      console.log(OP, ' json: ', json);
      const data = json['ticket'] as VirtualTicketState;
      if (!data) {
        console.error('did not receive ticket data');
        return;
      }
      dispatch(virtualTicketUpdate(json));
      const { oids, bets } = data;
      const betIds = oids.map((oid) => {
        return bets[oid].matchId;
      });

      console.debug(OP, ' updating selected odds: ', betIds, oids);
      dispatch(updateSelectedOdds(betIds, oids));
    })
    .catch((error) => {
      console.error(OP, error);
    });
};

export const virtualTicketResetMiza = (): StoreTypes.AppThunk => (
  dispatch,
  getState
) => {
  const {
    system: { sessionId },
    virtualTicket: { cookies, currentTicket, shopReady },
  } = getState();
  const OP = 'virtualTicketResetMiza';
  document.dispatchEvent(new Event('click'));

  fetch(`${API_VIRTUAL_TICKET}/ticket/reset-miza`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      sessionId,
      cookies,
      ticket: currentTicket,
      shopReady: shopReady[currentTicket - 1],
    }),
  })
    .then((res) => res.json())
    .then((json) => {
      console.log(OP, ' json: ', json);
      const data = json['ticket'] as VirtualTicketState;
      if (!data) {
        console.error('did not receive ticket data');
        return;
      }
      dispatch(virtualTicketUpdate(json));
      const { oids, bets } = data;
      const betIds = oids.map((oid) => {
        return bets[oid].matchId;
      });

      console.debug(OP, ' updating selected odds: ', betIds, oids);
      dispatch(updateSelectedOdds(betIds, oids));
    })
    .catch((error) => {
      console.error(OP, error);
    });
};

export const deleteCurrentVirtualTicket = (): StoreTypes.AppThunk => (
  dispatch,
  getState
) => {
  const {
    system: { sessionId },
    virtualTicket: { cookies, currentTicket },
  } = getState();
  const OP = 'deleteCurrentVirtualTicket';
  document.dispatchEvent(new Event('click'));

  fetch(`${API_VIRTUAL_TICKET}/ticket/delete`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      sessionId,
      cookies,
      ticket: currentTicket,
    }),
  })
    .then((res) => res.json())
    .then((json) => {
      console.log(OP, ' json: ', json);
      const data = json['ticket'] as VirtualTicketState;
      if (!data) {
        console.error('did not receive ticket data');
        return;
      }
      dispatch(virtualTicketUpdate(json));
      const { oids, bets } = data;
      const betIds = oids.map((oid) => {
        return bets[oid].matchId;
      });

      console.debug(OP, ' updating selected odds: ', betIds, oids);
      dispatch(updateSelectedOdds(betIds, oids));
    })
    .catch((error) => {
      console.error(OP, error);
    });
};
