/** @format */

import { applyMiddleware, createStore, StoreEnhancer, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';

import { composeWithDevTools, EnhancerOptions } from 'redux-devtools-extension';
import monitorReducersEnhancer from './enhancers/monitorReducers';
import loggerMiddleware from './middleware/logger';
import wsMiddlerware from './middleware/ws';
import { rootReducer, RootState } from '.';

export default function configureStore(preloadedState?: RootState) {
  const middlewares = [loggerMiddleware, wsMiddlerware, thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);
  if (process.env.NODE_ENV === 'development') {
    // middlewares.push(secretMiddleware)
  }

  const enhancers = [
    middlewareEnhancer,
    monitorReducersEnhancer as StoreEnhancer,
  ];

  let composedEnhancers = compose;
  if (process.env.NODE_ENV === 'development') {
    composedEnhancers = composeWithDevTools({
      realtime: true,
      name: 'Oferta',
      hostname: 'localhost',
      port: '8001',
    } as EnhancerOptions);
  }

  const store = createStore(
    rootReducer,
    preloadedState,
    composedEnhancers(...enhancers)
  );
  //   if (process.env.NODE_ENV !== 'production' && module.hot) {
  //     module.hot.accept('./', () => store.replaceReducer(rootReducer));
  //   }

  return store;
}
