import {
  RESET_TICKET,
  START_SESSION,
  ActionType,
  UPDATE_TICKETS,
  SHOW_PREPARED_TICKETS,
  VirtualTicketState,
  TICKET_TOGGLE_EXPANDED,
  CHANGE_CURRENT_TICKET,
  CHANGE_CURRENT_PREPARED_TICKET,
  DELETE_VIRTUAL_TICKET_MESSAGE,
  DELETE_PREPARED_VIRTUAL_TICKET,
} from './ticket-types';

export function deletePreparedVirtualTicket(ticket: number): ActionType {
  return {
    type: DELETE_PREPARED_VIRTUAL_TICKET,
    meta: {
      ticket,
    },
  };
}

export function deleteVirtualTicketMessage(index: number): ActionType {
  return {
    type: DELETE_VIRTUAL_TICKET_MESSAGE,
    meta: {
      index,
    },
  };
}

export function changeCurrentTicket(ticket: number): ActionType {
  return {
    type: CHANGE_CURRENT_TICKET,
    meta: {
      ticket,
    },
  };
}

export function changeCurrentPreparedTicket(ticket: number): ActionType {
  return {
    type: CHANGE_CURRENT_PREPARED_TICKET,
    meta: {
      ticket,
    },
  };
}

export function toggleTicketExpanded(): ActionType {
  return {
    type: TICKET_TOGGLE_EXPANDED,
  };
}
export function showPreparedTickets(): ActionType {
  return {
    type: SHOW_PREPARED_TICKETS,
  };
}

export function resetTicketAction(ticket: number): ActionType {
  return {
    type: RESET_TICKET,
    meta: {
      ticket_number: ticket,
    },
  };
}

export function virtualTicketStartSession(cookies: string): ActionType {
  return {
    type: START_SESSION,
    meta: {
      cookies: cookies,
    },
  };
}

export function virtualTicketUpdate(
  json: {
    cookies: string;
    ticket: VirtualTicketState;
  },
  showPrepared = false
): ActionType {
  return {
    type: UPDATE_TICKETS,
    meta: {
      cookies: json['cookies'],
      data: json['ticket'],
      showPrepared: showPrepared,
    },
  };
}
