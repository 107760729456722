import React from 'react';
import { RootState } from '../../store';
import { Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
// import { ReactComponent as TicketIcon } from '../../img/vticket-24px.svg';
import { ReactComponent as ArrowUp } from '../../img/arrow-up-24px.svg';
import { ReactComponent as ArrowDown } from '../../img/arrow-down-24px.svg';
import { toggleTicketExpanded } from '../../store/ticket/ticket-actions';
// import Spinner from '../Spinner';

const PreparedVirtualTicketsHeader: React.FC<ReduxProps> = (props) => {
  const { toggleExpanded, isExpanded, isEmpty, preparedTickets } = props;

  const handleExpand = () => {
    toggleExpanded();
  };
  const pluralSuffix = preparedTickets.length !== 1 ? 'e' : '';
  const countPreparedTicketsString = `${preparedTickets.length} Bilet${pluralSuffix} pregătit${pluralSuffix}`;
  const totalPayment = preparedTickets
    .map((ticket) => {
      return ticket.miza;
    })
    .reduce((total, current) => {
      return total + current;
    }, 0);

  const totalPaymentString =
    totalPayment === 0
      ? ''
      : ` / Total de plată: ${totalPayment.toFixed(2)} lei`;
  return (
    <div className='ticket-header' onClick={handleExpand}>
      <div style={{ display: 'inline-block', marginRight: 16 }}>
        {isExpanded && <ArrowDown />}
        {!isExpanded && <ArrowUp />}
      </div>
      <div className='v-stack margin-r-36'>
        <span className='ticket-header-label'>
          {countPreparedTicketsString}
          {totalPaymentString}
        </span>
      </div>

      <div className='flex-spacer' />

      {!isEmpty && (
        <>
          {/* <div className='ticket-button'>
            <span className='inline-block'>Sterge bilet</span>
          </div> */}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { isExpanded, preparedTickets } = state.virtualTicket;

  return {
    isExpanded,
    preparedTickets,
    isEmpty: preparedTickets.length === 0,
  };
};

const mapDispatchActions = (dispatch: Dispatch) => {
  return {
    dispatch,
    toggleExpanded: () => dispatch(toggleTicketExpanded()),
  };
};
const connector = connect(mapStateToProps, mapDispatchActions);

type ReduxProps = ConnectedProps<typeof connector>;

const ConnectedPreparedVirtualTicketsHeader = connector(
  PreparedVirtualTicketsHeader
);
export default ConnectedPreparedVirtualTicketsHeader;
