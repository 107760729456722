import React, { useEffect } from 'react';

// const API_SYSTEM = process.env.REACT_APP_API;
interface Props {
  isTouchscreen: boolean;
  onInteraction: () => void;
}

const MoviePlayer: React.FC<Props> = (props) => {
  const { isTouchscreen, onInteraction } = props;
  const [loopCount, setLoopCount] = React.useState(0);
  const [timeUpdate, setTimeUpdate] = React.useState(-1);
  const timer = React.useRef(0.0);

  const player = React.useRef<HTMLVideoElement | null>(null);
  const poster = isTouchscreen
    ? undefined
    : `${process.env.PUBLIC_URL}/media/tab-v1.jpg`;

  const src = `${process.env.PUBLIC_URL}/media/${
    isTouchscreen ? 'touchscreen20210427.mp4' : 'tablet20210427.mp4'
  }`;

  useEffect(() => {
    setTimeUpdate(-1);
    player.current?.play();
  }, [loopCount]);

  useEffect(() => {
    if (timeUpdate === -1) {
      window.clearTimeout(timer.current);
    } else {
      timer.current = window.setTimeout(fixVideo, 3000);
    }
    return () => {
      window.clearTimeout(timer.current);
    };
  }, [timeUpdate]);

  const fixVideo = () => {
    console.warn('Video is not playing. Will force a video reload.');
    player.current?.load();
  };

  return (
    <video
      ref={player}
      width={isTouchscreen ? 1920 : 1280}
      height={isTouchscreen ? 1080 : 800}
      muted
      poster={poster}
      className='fs-video'
      autoPlay
      controls={false}
      // preload='auto'
      onClick={onInteraction}
      onTouchStart={onInteraction}
      onPointerDown={onInteraction}
      onDragStart={onInteraction}
      onPlaying={(e) => {
        setTimeUpdate(0);
      }}
      onError={(e) => {
        setTimeUpdate(0);
      }}
      onEnded={(e) => {
        setLoopCount(loopCount + 1);
      }}
      onTimeUpdate={(e) => {
        setTimeUpdate(timeUpdate + 1);
      }}
    >
      <source src={src} type='video/mp4' />
    </video>
  );
};
export default MoviePlayer;
