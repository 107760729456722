import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSWR from 'swr';
import { RootState } from '../../store';
import { ReactComponent as PaperCutDecoration } from '../../img/paper-cut-22px.svg';
import VirtualTicketBetRow from '../VirtualTicket/VirtualTicketBetRow';
import { wsMessage } from '../../store/system/actions';

const fetcher = (
  url: string,
  ticketId: string,
  protectionCode: string,
  sessionId: string,
  appId: string
) =>
  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ticketId, protectionCode, sessionId, appId }),
  })
    .then((res) => res.json())
    .then((json) => {
      console.log('Fetch ticket results', ' json: ', json);
      if (json['result'] === '0') {
        throw Error('Ticket not found');
      }
      if (json['result'] !== '1') {
        throw Error(`Unexpected result: ${json['result']}`);
      }
      const data = json['data']; //['ticket'] as VirtualTicketState;
      return data;
    });

const API_VIRTUAL_TICKET = process.env.REACT_APP_VIRTUAL_TICKET_API;

const ModeInfo: { [key: string]: string } = {
  SOLO: 'Simplu',
  AKO: 'Simplu',
  COMBI: 'MAXIKOMBI',
  SYSTEM: 'SISTEM',
};

export enum BetStatus {
  Accepted,
  NonWinning,
  Winning,
  Unresolved,
}

const StatusInfo: {
  [key: string]: { status: BetStatus; label: string; color: string };
} = {
  WINNING: { status: BetStatus.Winning, label: 'Câștigător', color: '#19FF84' },
  NON_WINNING: {
    status: BetStatus.NonWinning,
    label: 'Necâștigător',
    color: '#FFA89F',
  },
  ACCEPTED: {
    status: BetStatus.Accepted,
    label: 'În desfășurare',
    color: '#ffd639',
  },
  UNRESOLVED: {
    status: BetStatus.Unresolved,
    label: 'Indecis',
    color: '#ffd639',
  },
};

interface TicketDetail {
  date: string;
  mode: string;
  status: string;
  time: string;
  ticketID: string;
  kind: string;
  summary: {
    shortcode: string;
    // totalBetAmount,
    totalOddsValue: number;
    totalPrice: number;
    totalPrize: number;
    totalBetValue: number;
    handlingChargeValue: number;
    extraChargeValue: number;
  };
  preview: {
    group: Array<{
      group: string;
      count: number;
      odds: number;
      isBanker: boolean;
      betType: Array<{
        action: string;
        actionID: string;
        active: boolean;
        datetime: string;
        icon: string;
        infoID: number;
        itemID: number;
        name: string;
        result: string;
        score: string;
        sport: string;
        sportID: number;
        subname: string;
        odds: {
          oddsID: number;
          name: string;
          value: string;
          blocked?: boolean;
          frozen?: boolean;
          result?: string;
        };
      }>;
    }>;
  };
}

const TicketResults: React.FC<{
  ticketId: string;
  protectionCode: string;
  onError: () => void;
}> = (props) => {
  const { ticketId, protectionCode, onError } = props;
  const sessionId = useSelector<RootState>((state) => state.system.sessionId);
  const appId = useSelector<RootState>((state) => state.system.uuid);
  const isTouchScreen = useSelector<RootState>(
    (state) => state.system.isTouchscreen
  );
  const dispatch = useDispatch();
  const { data, error } = useSWR(
    [
      `${API_VIRTUAL_TICKET}/ticket/check`,
      ticketId,
      protectionCode,
      sessionId,
      appId,
    ],
    fetcher,
    {
      revalidateOnFocus: false,
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        console.log('error retry! ', error);
        if (`${error}` === 'Error: Ticket not found') {
          console.log('should stop retrying.');
          return;
        }
        // Only retry up to 10 times.
        if (retryCount >= 10) return;

        // Retry after 5 seconds.
        setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 5000);
      },
    }
  );

  useEffect(() => {
    if (data || error) {
      dispatch(
        wsMessage({
          appId,
          sessionId,
          isTouchscreen: false,
          rx_action: {
            type: 'CHECK_TICKET',
            code: `${ticketId}-${protectionCode}`,
            error: error,
          },
          dt: 0,
        })
      );
    }
  }, [dispatch, appId, sessionId, ticketId, protectionCode, error, data]);

  if (error)
    return (
      <div className='w-screen h-screen text-white text-20 semibold flex flex-col items-center content-center screen-padding box-border'>
        <div>Biletul tău nu a fost găsit. Te rugăm să încerci din nou.</div>
        <div className='text-center mt-32'>
          <div
            className='inline-block h-44 lh-44 w-100 text-center text-13 text-dark bold bg-yellow rounded-4'
            onClick={onError}
            style={{ width: '160px' }}
          >
            OK
          </div>
        </div>
      </div>
    );
  if (!data)
    return (
      <div className='w-screen h-screen text-white  text-20 semibold flex flex-row items-center content-center screen-padding box-border'>
        <div>Se caută biletul..</div>
      </div>
    );

  const ticket: TicketDetail = data.ticketDetail;

  const modeName: string = ModeInfo[ticket.mode as string] ?? '*';
  const statusLabel = StatusInfo[ticket.status] ?? 'În desfășurare';

  // render data
  return (
    <div className='w-screen h-screen text-white flex flex-row items-center content-center screen-padding box-border'>
      <div className='flex flex-col h-full ' style={{ width: 420 }}>
        <h1 className='text-24 bold'>Verifică bilet</h1>
        <h2 className='text-12 semibold'>
          Această verificare nu reprezintă omologarea oficială a Biletului!
        </h2>
        <div className='' style={{ height: 14 }}>
          <PaperCutDecoration />
        </div>
        <div className='bg-white text-gray-100 text-14 p-32 flex-grow'>
          <div className='flex space-between lh-32 items-center mb-32'>
            <div className='inline-block text-24 bold'>
              {ticket.summary.shortcode}
            </div>
            <div
              className='inline-block text-14 semibold px-12 rounded-17'
              style={{ backgroundColor: statusLabel.color }}
            >
              {statusLabel.label}
            </div>
          </div>
          <div className='text-right py-16 border-solid border-y-2 border-black'>
            <b>{modeName}</b>
          </div>
          <div className='h-48 lh-48 flex space-between border-b-dashed-1'>
            <b>Cota totală</b>
            <span>{Number(ticket.summary.totalOddsValue).toFixed(2)}</span>
          </div>
          <div className='h-48 lh-48 flex space-between border-b-dashed-1'>
            <b>Miza</b>
            <span>{Number(ticket.summary.totalBetValue).toFixed(2)}</span>
          </div>
          <div className='h-48 lh-48 flex space-between border-b-dashed-1'>
            <b>Comision</b>
            <span>{Number(ticket.summary.handlingChargeValue).toFixed(2)}</span>
          </div>
          <div className='h-48 lh-48 flex space-between'>
            <b>De plată</b>
            <span>{Number(ticket.summary.totalPrice).toFixed(2)}</span>
          </div>
          <div className='h-48 lh-48 flex space-between border-solid border-y-2 border-black'>
            <b>Câștiguri</b>
            <span>{Number(ticket.summary.totalPrize).toFixed(2)}</span>
          </div>
          <div className='h-48 lh-48 flex space-between'>
            <b>Taxă suplimentară</b>
            <span>{Number(ticket.summary.extraChargeValue).toFixed(2)}</span>
          </div>

          <div className='inline-block flex-grow flex-col'>&nbsp;</div>
          <div className='h-48 lh-48 flex space-between'>
            <span>{ticket.ticketID}</span>
            <span>
              {ticket.date}&nbsp;{ticket.time}
            </span>
          </div>
        </div>
        <div className='' style={{ height: 14, transform: 'rotate(180deg)' }}>
          <PaperCutDecoration />
        </div>
      </div>
      <div
        className='overflow-y-scroll ml-24 items-start h-full p-t-24 box-border'
        style={{ paddingTop: 64 }}
      >
        {' '}
        {ticket.kind === 'LOTO' &&
          ticket.preview.group.map((g) => (
            <div key={g.group}>
              {g.betType.map((b, bindex) => (
                <VirtualTicketBetRow
                  key={b.infoID}
                  editable={false}
                  roundedTop={true}
                  code={`${b.infoID}`}
                  name={b.name}
                  title={b.name}
                  id={`${b.itemID}`}
                  otherOdds={[]}
                  icon='icon-sport-lotto' //{`icon-sport-${b.icon}`}
                  //   mode={ticket.mode}
                  mode='AKO'
                  index={`${bindex}`}
                  matchId=''
                  sport={b.sport}
                  sportId={`${b.sportID}`}
                  competition={b.action}
                  competitionId={b.actionID}
                  //   fixed={ticket.mode === 'SYSTEM' && g.isBanker === true}
                  fixed={false}
                  odd={{ name: b.odds.name, value: b.odds.value }}
                  description={b.subname}
                  groups={[]}
                  class=''
                  extraClassName='checkticket-row'
                  status={StatusInfo[b.result].status}
                />
              ))}
            </div>
          ))}
        {ticket.kind === 'MAIN' &&
          ticket.preview.group.map((g) => (
            <div key={g.group}>
              {ticket.mode === 'COMBI' && (
                <div key={g.group} className='mt-24 checkticket-row'>
                  <div
                    className='h-36 lh-36 text-11 text-white semibold flex mb-1'
                    style={{ marginRight: 54 }}
                  >
                    <div className='inline-flex space-between flex-grow bg-gray-gradient rounded-t mr-1 px-12 box-border'>
                      <span>
                        GRUP <span className='text-yellow'>{g.group}</span>
                      </span>
                      <span>
                        {g.count} {g.count !== 1 ? 'Pariuri' : 'Pariu'}
                      </span>
                    </div>
                    <div
                      className='inline-flex space-between bg-gray-gradient rounded-t pl-12 box-border'
                      style={{ width: 119 }}
                    >
                      <span>Cota</span>
                      <span className='text-yellow w-58 text-center'>
                        {g.odds.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {g.betType.map((b, bindex) => (
                <VirtualTicketBetRow
                  key={b.infoID}
                  editable={false}
                  roundedTop={true}
                  code={`${b.infoID}`}
                  name={b.name}
                  title={b.name}
                  id={`${b.itemID}`}
                  otherOdds={[]}
                  icon={`icon-sport-${b.icon}`}
                  mode={ticket.mode}
                  index={`${bindex}`}
                  matchId=''
                  sport={b.sport}
                  sportId={`${b.sportID}`}
                  competition={b.action}
                  competitionId={b.actionID}
                  fixed={ticket.mode === 'SYSTEM' && g.isBanker === true}
                  odd={{ name: b.odds.name, value: b.odds.value }}
                  description={b.subname}
                  groups={[]}
                  class=''
                  extraClassName='checkticket-row'
                  status={StatusInfo[b.result].status}
                />
              ))}
            </div>
          ))}
      </div>
    </div>
  );
};
export default TicketResults;
