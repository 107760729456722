/** @format */
import Redux from 'redux';

const monitorReducerEnhancer = (createStore: Redux.StoreCreator) => (
  reducer: Redux.Reducer,
  initialState: any,
  enhancer: Redux.StoreEnhancer
) => {
  const monitoredReducer = (state: any, action: Redux.Action) => {
    // const start = performance.now();
    const newState = reducer(state, action);
    // const end = performance.now();
    // const diff = round(end - start);

    // console.log('reducer process time:', diff);

    return newState;
  };

  return createStore(monitoredReducer, initialState, enhancer);
};

export default monitorReducerEnhancer;
