import React, { MouseEventHandler } from 'react';

import Bet from './Bet';
import { OfferActions } from '../store';
import * as selectors from '../store/selectors';

import { BetType } from '../store/offer/types';
import { useDispatch, useSelector } from 'react-redux';

export type Page = {
  id: string;
  index: number;
  tournaments: {
    id: string;
    name: string;
    type: BetType;
    bets: string[];
    showTH: boolean;
  }[];
  // bets: string[];
  offset: number;
  size: number;
  hidden: boolean;
};

const DateAndTime: React.FC<{ type: BetType }> = (props) => {
  const type = props.type;
  const tooManyOdds: boolean = type.odds.length > 6;
  return (
    <>
      {tooManyOdds && null}
      {!tooManyOdds && (
        <>
          <div className='t-tdate'>DATA</div> <div className='t-tdate'>ORA</div>
        </>
      )}
    </>
  );
};

interface Props {
  isVisible: boolean;
  page: Page;
  categoryName: string;
}

const TouchScreenPage: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { isVisible, page, categoryName } = props;
  const onShowDetails = (e: React.SyntheticEvent<HTMLDivElement>) => {
    console.log('show details');
    const { tid, bid } = e.currentTarget.dataset;

    dispatch(OfferActions.showTournamentDetails(tid ?? '', bid));
  };

  return (
    <div
      className={'page'}
      style={{
        left: page.offset,
        width: page.size,
        backgroundColor: `${!isVisible ? '#333' : ''}`,
      }}
      key={page.id}
    >
      {isVisible &&
        page.tournaments.map((t) => (
          <React.Fragment key={t.id}>
            {t.showTH && (
              <div className='t-hc' key={t.id}>
                <div className='t-h' data-tid={t.id}>
                  <div className='t-title'>
                    {categoryName}
                    <span> &gt; </span>
                    {t.name}
                  </div>
                  {DateAndTime({ type: t.type })}
                  {t.type.odds.slice(0, 9).map((odd, i) => (
                    <b className='t-oname' key={`${i}+${odd}`}>
                      {odd}
                    </b>
                  ))}
                </div>
              </div>
            )}
            {t.bets.map((b) => (
              <Bet key={b} b_id={b} t_id={t.id} onClick={onShowDetails} />
            ))}
          </React.Fragment>
        ))}
    </div>
  );
};

export default TouchScreenPage;
