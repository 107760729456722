import React, {
  // useState,
  // useLayoutEffect,
  useEffect,
  useCallback,
} from 'react';
import { Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { debounce } from 'lodash';
import { RootState } from '../store';

import * as selectors from '../store/selectors';
import TournamentTable from './TournamentTable';
import { wsMessage } from '../store/system/actions';
import { motion } from 'framer-motion';
import TournamentNavigator from './TournamentNavigator';
interface Props extends PropsFromRedux {
  isTouchscreen: boolean;
}
type TargetWithValue = {
  value: string;
};

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const OfferContent: React.FC<Props> = (props: Props) => {
  const { tournaments, category, dispatch, appId, sessionId, date } = props;
  const categoryName = category?.name.toUpperCase() || '';
  const ul = React.useRef<HTMLDivElement>(null);
  const lastScroll = React.useRef(0);

  const logTAScroll = useCallback(
    (top: number) => {
      console.log('scrolling');
      if (top === lastScroll.current) {
        return;
      }

      lastScroll.current = top;
      dispatch(
        wsMessage({
          appId,
          sessionId,
          isTouchscreen: false,
          rx_action: {
            type: 'WILL_SCROLL',
            top,
            c_id: category?.id,
            c_name: category?.name,
            date,
          },
          dt: 0,
        })
      );
    },
    [lastScroll, appId, sessionId, dispatch, category, date]
  );

  const debouncedLogTAScroll = useCallback(debounce(logTAScroll, 300), [
    logTAScroll,
    sessionId,
  ]);

  const onDidScrollTablet = useCallback(
    (e?: Event) => {
      if (!ul.current) {
        return;
      }

      const top = ul.current.scrollTop;
      debouncedLogTAScroll(top);
    },
    [debouncedLogTAScroll]
  );

  // const tryScroll = (up: boolean) => {
  //   if (!ul.current) {
  //     return;
  //   }
  //   const theul = ul.current!;
  //   let w = theul.offsetWidth;
  //   let options: ScrollToOptions = { behavior: 'smooth' };

  //   if (columns.current.length === 0) {
  //     options.left = w * (up ? -1 : 1);
  //     theul.scrollBy(options);
  //   } else {
  //     let ol = theul.scrollLeft;
  //     let offset = ol;
  //     if (up) {
  //       let cols = columns.current.slice();
  //       let v = cols.pop();
  //       while (v !== undefined) {
  //         if (v < offset) {
  //           offset = v;
  //           v = undefined;
  //         } else {
  //           v = cols.pop();
  //         }
  //       }
  //     } else {
  //       let cols = columns.current.slice();
  //       let v = cols.shift();
  //       while (v !== undefined) {
  //         if (v > offset) {
  //           offset = v;
  //           v = undefined;
  //         } else {
  //           v = cols.shift();
  //         }
  //       }
  //     }

  //     options.left = offset;
  //     // debouncedTSLogScroll(up, options);
  //     theul.scrollTo(options);
  //   }
  // };

  useEffect(() => {
    const container = ul.current;

    container?.scrollTo({ top: 0 });
  }, [tournaments]);

  useEffect(() => {
    const container = ul.current;

    container?.addEventListener('scroll', onDidScrollTablet);
    return () => {
      container?.removeEventListener('scroll', onDidScrollTablet);
    };
  }, [logTAScroll, appId, sessionId, onDidScrollTablet]);

  // useLayoutEffect(() => {
  //   const container = ul.current;

  //     return;

  // }, [tournaments]);

  const handleScrollToTournament = (tournament_id: string) => {
    const th = document.querySelector<HTMLElement>(
      `div.t-h[data-tid="${tournament_id}"]`
    );
    const options: ScrollToOptions = {
      behavior: 'smooth',
    };
    options.top = th?.offsetTop ?? 0;

    document.querySelector('.content-wrapper')?.scrollTo(options);
  };

  return (
    <>
      <TournamentNavigator
        isTouchscreen={false}
        onScroll={handleScrollToTournament}
      />
      <motion.div
        ref={ul}
        className='tablet content-wrapper'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {tournaments.map((t) => (
          <TournamentTable tid={t} key={t} categoryName={categoryName} />
        ))}
      </motion.div>
      {/* 
      <div id='offer-scroll-ct' className={`scroll-controller hidden`}>
        <div
          className={`scroll-up ${canScrollLeft ? 'active' : 'disabled'}`}
          onClick={() => {
            tryScroll(true);
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
          >
            <g id='arrow-more-24px' transform='translate(24 4) rotate(90)'>
              <path
                id='Path_36'
                data-name='Path 36'
                d='M16.8,9.2h0a.967.967,0,0,0-1.4,0L12,12.6,8.6,9.2a.967.967,0,0,0-1.4,0h0a.967.967,0,0,0,0,1.4L12,15.5l4.8-4.9A.967.967,0,0,0,16.8,9.2Z'
                fill='#5d5d5d'
              />
            </g>
          </svg>
        </div>
        <div
          className={`scroll-down ${canScrollRight ? 'active' : 'disabled'}`}
          onClick={() => {
            tryScroll(false);
          }}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
          >
            <g id='arrow-more-24px' transform='translate(0 28 ) rotate(-90)'>
              <path
                id='Path_36'
                data-name='Path 36'
                d='M16.8,9.2h0a.967.967,0,0,0-1.4,0L12,12.6,8.6,9.2a.967.967,0,0,0-1.4,0h0a.967.967,0,0,0,0,1.4L12,15.5l4.8-4.9A.967.967,0,0,0,16.8,9.2Z'
                fill='#5d5d5d'
              />
            </g>
          </svg>
        </div>
      </div>
    
       */}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const category = selectors.getSelectedCategory(state);
  const date = state.offer.selectedDate;

  let tournaments: string[] = [];
  if (!(!category || !date)) {
    tournaments = category.tournamentsByDate[date];
  }

  return {
    tournaments,
    category,
    appId: state.system.uuid,
    sessionId: state.system.sessionId,
    date,
  };
};

const mapDispatchActions = (dispatch: Dispatch) => {
  return { dispatch };
};
const connector = connect(mapStateToProps, mapDispatchActions);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OfferContent);
